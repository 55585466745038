/* Import Jost font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/brockmann");

/* General Styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Brockmann", sans-serif;
  background-color: #000; /* Light background color for the page */
  color: #f2f2f2; /* Default text color */
}

.superscript {
  vertical-align: super; /* Aligns the text as superscript */
  font-size: 32px; /* Makes the font size smaller than the surrounding text */
}

.text-with-svg-underline {
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
}

.text-with-svg-underline svg {
  display: block;
  width: 100%; /* Adjust this as needed */
  height: auto; /* This might need tuning based on your specific SVG */
  position: absolute;
  left: 0;
  bottom: -28px; /* Adjust this value based on your text size and desired underline position */
}

.full-container {
  background-color: #f2f2f2;
}

.navbar {
  color: #f2f2f2;
  position: relative;
  z-index: 1000;
}

@keyframes fadeBackground {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.nav-link {
  color: #f2f2f2;
}

.home-container {
  position: relative;
  overflow: hidden;
  color: #f2f2f2;
  padding-top: 60px; /* Add padding to account for the navbar */
  z-index: 2;
}

.home-content {
  position: relative;
  z-index: 2;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding-bottom: 2rem; /* Add some bottom padding as well */
}

.planet-system {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  overflow: visible;
}

.planet {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.moon {
  position: absolute;
  width: 5%;
  height: 5%;
  background-color: #f2f2f2;
  border-radius: 50%;
  box-shadow: inset -10px 0 #d3d3d3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: orbit 15s linear infinite;
}

@keyframes orbit {
  from {
    transform: translate(-50%, -50%) rotate(0deg) translateX(200px) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg) translateX(200px)
      rotate(-360deg);
  }
}

.home-starry {
  height: 100%;
}

.starry-section {
  background: linear-gradient(135deg, #0a0423 0%, #1a0b2e 50%, #000000 100%);
  position: relative;
  overflow: hidden;
  animation: gradientShift 15s ease infinite;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.star {
  position: absolute;
  background: radial-gradient(
    ellipse at center,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 50%;
  animation: twinkleEnhanced 5s infinite;
}

.star-small {
  width: 1px;
  height: 1px;
  box-shadow: 0 0 2px 0px rgba(255, 255, 255, 0.5);
}

.star-medium {
  width: 2px;
  height: 2px;
  box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.6);
}

.star-large {
  width: 3px;
  height: 3px;
  box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.7);
}

@keyframes twinkleEnhanced {
  0%,
  100% {
    opacity: 0.2;
    transform: scale(1) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: scale(1.5) rotate(180deg);
  }
}

.navbar,
.starry-section > * {
  position: relative;
  z-index: 10;
}

.landing-img {
  max-width: 100%;
  max-height: 100%;
}

.count-up-wrapper {
  text-align: center;
  font-family: "League Spartan", sans-serif;
  font-size: 0.8rem;
  color: #f2f2f2;
  margin-top: -10px;
}

.count-up {
  font-weight: 700;
  font-size: 1rem;
}

.count-up-text {
  opacity: 0.8;
}

@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulseGlow {
  0%,
  100% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 20px rgba(31, 156, 219, 0.3), 0 0 30px rgba(132, 38, 248, 0.3);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.5),
      0 0 30px rgba(31, 156, 219, 0.5), 0 0 40px rgba(132, 38, 248, 0.5);
  }
}

.landing-header {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 1.3;
  margin-bottom: 1.2rem;
  color: #ffffff;
  text-align: left;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.landing-header span {
  display: block;
  font-size: 3rem;
  font-weight: 900;
  color: #ffffff;
  margin-top: 0.8rem;
  position: relative;
  padding-left: 2px;
  text-shadow: 0 0 10px rgba(0, 255, 153, 0.5);
}

@media (max-width: 768px) {
  .landing-header {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 0.8rem;
  }
  .landing-header span {
    font-size: 1.8rem;
    margin-top: 0.5rem;
  }
  .landing-text {
    padding: 1rem;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .landing-header {
    font-size: 2.6rem;
  }
  .landing-header span {
    font-size: 2.4rem;
  }
}

.landing-header span::after {
  content: "🚀";
  position: absolute;
  right: -35px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(-50%) translateX(0);
  }
  50% {
    transform: translateY(-50%) translateX(5px);
  }
}

@media (max-width: 768px) {
  .landing-header {
    font-size: 2.2rem;
  }
  .landing-header span {
    font-size: 2rem;
  }
  .landing-header span::after {
    font-size: 1.5rem;
    right: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing-text {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.2rem 1.5rem;
  margin: 1.5rem 0;
  color: #ffffff;
  font-size: 1.1rem;
  line-height: 1.6;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .landing-text {
    padding: 1rem;
    font-size: 1rem;
  }
}

.neon-pink {
  color: #8426f8;
  text-shadow: 2px 2px 1px #1f9cdb;
}

.neon-blue {
  color: #1f9cdb;
  text-shadow: 2px 2px 1px #8426f8;
}
.landing-btn {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  padding: 12px 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.4s ease;
  color: #ffffff; /* White text */
  border-radius: 5px; /* Rounded corners for a modern look */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  box-shadow: 0 0 2px #8426f8, 0 0 5px #8426f8, 0 0 10px #8426f8;
}

.landing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #8426f8, #1f8adb);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.4s ease;
}

.landing-btn:hover::before {
  opacity: 1;
}

.icon-container i {
  font-size: 3rem; /* Adjust the size as needed */
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.circle-logos-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px; /* Space between logos */
}

.circle-logo {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the logos circular */
  object-fit: cover; /* Ensures the logos fit inside the circle */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adds a slight shadow */
  border: 2px solid white; /* Adds a border to each logo */
  margin-left: -15px; /* Overlap effect */
}

.arrow-icon {
  font-size: 24px; /* Adjust size as needed */
  color: #f2f2f2; /* Adjust color as needed */
  transform: rotate(-45deg); /* Rotates the arrow to point diagonally */
}

.trusted-text {
  font-size: 18px; /* Adjust size as needed */
  color: #f2f2f2;
}

.custom-btn {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  padding: 12px 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.4s ease;
  color: #ffffff; /* Ensures text is white for better readability */
}

.custom-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #8426f8, #1f8adb);
  opacity: 0;
  z-index: -1;
  transition: opacity 0.4s ease;
}

.custom-btn:hover::before {
  opacity: 1;
}

.price-btn:active {
  background-color: #00bfa6b4 !important;
  border-color: #00bfa6 !important;
}

.green-txt {
  color: #00bfa6;
}

.icons-txt {
  display: flex !important;
  align-items: center !important;
  width: 60%;
  margin-left: 12px;
}

.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
}
j .carousel {
  width: 600px;
  height: 400px;
}

.process-circle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em; /* Adjust size as needed */
  background-color: #00bfa6; /* or any color you prefer */
  color: white; /* Text color */
  margin: 0 auto; /* Center in column */
}

.circle-txt {
  font-size: 28px;
}

/* footer css */

.footer-div {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  background-color: #f2f2f2;
  color: #000;
}

.footer-links {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap; /* Allows nav links to wrap in smaller screens */
  justify-content: center; /* Centers links when they wrap */
}

.footer-links-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-link {
  position: relative;
  color: #000;
  margin: 0px 24px;
}

.link__hover--effect:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0%;
  bottom: -3px;
  left: 0;
  transition: all 300ms ease;
}
.link__hover--effect:hover:after {
  width: 100%;
  right: 0;
}

.link__hover--effect--white:after {
  background-color: white;
}

.copyright {
  margin-top: 40px;
  color: #000;
}

button {
  border: none;
  font-size: 16px;
}

.feature-logo {
  padding: 8px;
  height: 80px;
  width: 80px;
  border: 2px solid rgba(128, 128, 128, 0.514);
  border-radius: 260px;
  background-color: white;
}

.pricing-card {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 600px;
  box-shadow: 0 4px 8px #009884;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.pricing-card .card-body {
  display: flex;
  flex-direction: column;
  flex: 1; /* Forces the card body to expand and fill the space */
  justify-content: space-between; /* Spreads content to fill the available space, aligning button at the bottom */
}

.pricing-subtitle {
  font-size: 44px;
  font-weight: bold;
}

.price-btn {
  background-color: #ff7043; /* Bright Coral for visibility */
  color: #ffffff; /* White text */
  border: none; /* No border, but you could add a teal border if preferred */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px; /* Rounded corners for a modern look */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.testimonial-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.testimonial-card {
  margin: 0 20px;
  background-color: #fff; /* White background */
  box-shadow: 0 4px 8px 0 rgba(0, 128, 0, 0.2); /* Green shadow */
  transition: box-shadow 0.3s; /* Smooth transition for box-shadow */
}

.testimonial-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 128, 0, 0.2); /* Intensify shadow on hover */
}

.arrow-btn {
  background: none;
  border: none;
  color: white; /* White arrow */
  cursor: pointer;
  font-size: 32px; /* Size of the arrow icon */
  transition: transform 0.2s; /* Smooth transition for arrow buttons */
}

.arrow-btn:hover {
  transform: scale(1.2); /* Slightly enlarge arrows on hover */
}

.left-arrow,
.right-arrow {
  background-color: #00bfa6; /* Green background for arrows */
  border-radius: 50%; /* Circle shape */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-text {
  font-size: 1.2rem;
  color: #555;
}

.testimonial-rating {
  color: gold;
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.testimonial-author {
  font-style: italic;
}

/* Add to your style.css */

/* Transition for the testimonial card change */
.testimonial-card {
  opacity: 1;
  transition: opacity 0.5s ease;
}

/* ...repeat for each index or use JavaScript to dynamically adjust the opacity */

.marquee {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100px;
  border-radius: 32px;
}

.marquee-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(300%);
  position: absolute;
  animation: marqueeAnimation 40s linear infinite;
}

@keyframes marqueeAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.marquee img {
  width: 150px;
  height: auto;
  margin-right: 50px;
}

.wjec-logo {
  height: 80px !important;
}

/* cards */

/* Custom card styling */
.edu-card {
  min-height: 500px;
  height: 100%;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

/* Container for all cards */
.row {
  position: relative;
  z-index: 1;
}

/* Special color card (middle card) */
.edu-card.special-color {
  position: relative;
  z-index: 2;
  background: rgba(132, 38, 248, 0.15);
  border: 1px solid rgba(132, 38, 248, 0.3);
}

/* Recommended tag - with !important to override any conflicts */
.pricing-tag {
  position: absolute !important;
  top: -15px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #ff3366 !important;
  color: white !important;
  padding: 4px 12px !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  z-index: 9999 !important;
  text-transform: uppercase !important;
  width: 240px !important;
  height: auto !important;
  margin: 0 !important;
  display: block !important;
  text-align: center !important;
}

.pricing-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edu-card::after {
  content: "";
  position: absolute;
  inset: -1px;
  border-radius: 20px;
  padding: 1px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05) 50%,
    transparent 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.edu-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 48px rgba(31, 138, 219, 0.3);
}

.edu-card.special-color::after {
  background: linear-gradient(
    135deg,
    rgba(132, 38, 248, 0.3),
    rgba(31, 138, 219, 0.3)
  );
}

.edu-card-body {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: 1;
  background: radial-gradient(
    circle at top right,
    rgba(255, 255, 255, 0.05),
    transparent 300px
  );
}

.edu-card-title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  background: linear-gradient(135deg, #ffffff 0%, #1f8adb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  padding-bottom: 1rem;
}

.edu-card-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background: linear-gradient(90deg, #1f8adb, transparent);
}

.edu-card.special-color .edu-card-title::after {
  background: linear-gradient(90deg, #8426f8, transparent);
}

.edu-card-subtitle {
  font-size: 2.8rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 2rem;
  color: #ffffff;
  text-shadow: 0 0 20px rgba(31, 138, 219, 0.3);
}

.edu-card-text {
  flex-grow: 1;
  position: relative;
}

.edu-card-text ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.edu-card-text li {
  padding: 1rem 0;
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 2.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.edu-card-text li::before {
  content: "✦";
  position: absolute;
  left: 0;
  color: #1f8adb;
  font-weight: bold;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-btn {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 12px;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  color: white;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
}

.custom-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(31, 138, 219, 0.4);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .edu-card {
    min-height: auto;
  }

  .edu-card-body {
    padding: 1.5rem;
  }

  .edu-card-title {
    font-size: 1.5rem;
  }

  .edu-card-subtitle {
    font-size: 2.2rem;
  }

  .custom-btn {
    padding: 1rem;
  }

  .pricing-tag {
    font-size: 0.75rem;
    padding: 0.4rem 1rem;
    top: -12px;
  }

  .pricing-tag::before {
    font-size: 0.8rem;
  }
}

.edu-card.special-color .edu-card-text li::before {
  color: #8426f8;
}

.custom-btn {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  color: white;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.custom-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.custom-btn:hover::before {
  left: 100%;
}

.custom-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(31, 138, 219, 0.4);
}

/* CTA */
.why-creativz-container {
}
.testimonial-showcase {
}

.section-header {
  margin-bottom: 30px;
}

.reason-row .reason-col {
  text-align: center;
  margin-bottom: 30px;
}

.reason-content {
  padding: 20px;
  border-radius: 8px;
}

.reason-icon {
  margin-bottom: 15px;
  display: inline-block;
}

.reason-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #f2f2f2;
}

.reason-description {
  font-size: 1rem;
  color: rgb(162, 161, 161);
}

.reviews-row .review {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.review-footer {
  font-size: 0.875rem;
  color: #777;
}

.cta-button {
  margin-top: 20px;
  padding: 10px 30px;
  font-size: 1.25rem;
  background-color: #8426f8;
  border: none;
}

.accordion-item,
.accordion-item button {
  background-color: #000;
  color: #f2f2f2;
}

.custom-vh {
  min-height: 90vh;
}

.accordion-item button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.accordion .card-header {
  background-color: #000;
  cursor: pointer;
}

.accordion .card {
  border: none;
  border-bottom: 1px solid #e7e7e7;
}

.accordion .card:last-child {
  border-bottom: 0;
}

.accordion .btn-link {
  color: #0056b3;
  text-decoration: none;
  font-size: 18px;
}

.accordion .btn-link:hover {
  color: #004495;
}

.consent-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.consent-popup button {
  margin: 0 10px;
  padding: 5px 10px;
}

.generated-content h5 {
  color: #007bff;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.generated-content p {
  margin-left: 15px;
  line-height: 1.6;
}

.card {
  border-radius: 15px;
}

.card-body {
  padding: 1rem;
}

.btn-primary:hover {
  background-color: #00d0ff;
  border-color: #00d0ff;
}

.tiktok-embed-wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.video-stats {
  font-size: 0.9rem;
  color: #666;
}

.gradient-border {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 48px;
  text-align: center;
  font-weight: bold;
  border: 3px solid transparent;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #1f8adb, #8426f8);
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: #000;
}

.step-number {
  flex-shrink: 0;
}

.modal-content {
  background: rgba(36, 36, 36, 0.95);
  backdrop-filter: blur(20px);
  color: #ffffff;
  border-radius: 15px;
  border: 1px solid rgba(132, 38, 248, 0.3);
  box-shadow: 0 0 30px rgba(132, 38, 248, 0.2);
}

.modal-header {
  border-bottom: 1px solid rgba(132, 38, 248, 0.5);
  padding: 1.5rem;
}

.modal-title {
  color: #ffffff;
  font-weight: bold;
}

.modal-body {
  padding: 2rem;
}

.form-label {
  color: #ffffff;
  font-weight: 600;
}

.form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(132, 38, 248, 0.5);
  color: #ffffff;
  border-radius: 8px;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-control:focus {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #1f8adb;
  box-shadow: 0 0 0 0.2rem rgba(31, 138, 219, 0.25);
  color: #ffffff;
}

.modal-footer {
  border-top: 1px solid rgba(132, 38, 248, 0.5);
  padding: 1.5rem;
}

.btn-close {
  color: #ffffff;
  opacity: 0.8;
  filter: invert(1) grayscale(100%) brightness(200%);
}

.btn-close:hover {
  opacity: 1;
}

.btn-primary.modal-submit {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  padding: 0.75rem 2rem;
  font-weight: bold;
  transition: all 0.3s ease;
  color: #ffffff;
}

.btn-primary.modal-submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(31, 138, 219, 0.3);
}

/* Profile styles */
.profile-container {
  background-color: #2a2a2a;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.profile-sidebar {
  background-color: #333333;
  border: none;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.profile-sidebar .card-body {
  padding: 25px;
}

.profile-nav .nav-link {
  color: #f2f2f2;
  transition: all 0.3s ease;
}

.profile-nav .nav-link:hover,
.profile-nav .nav-link.active {
  background-color: #444444;
  color: #1f8adb;
}

.profile-content {
  background-color: #333333;
  border: none;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.profile-content .card-body {
  padding: 25px;
}

.profile-content h3,
.profile-content h5 {
  color: #1f8adb;
  margin-bottom: 20px;
}

.profile-content .list-group-item {
  background-color: #444444;
  color: #f2f2f2;
  border: 1px solid #555555;
}

.profile-content .progress-bar {
  background-color: #1f8adb;
}

.profile-content .btn-primary {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  transition: all 0.3s ease;
}

.profile-content .btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(31, 138, 219, 0.3);
}

.profile-content .btn-outline-primary {
  color: #1f8adb;
  border-color: #1f8adb;
}

.profile-content .btn-outline-primary:hover {
  background-color: #1f8adb;
  color: #ffffff;
}

.profile-content .btn-danger {
  background-color: #dc3545;
  border: none;
}

.profile-content .btn-danger:hover {
  background-color: #c82333;
}

.profile-content p,
.profile-content .list-group-item,
.profile-sidebar h4,
.profile-sidebar p {
  color: #f2f2f2;
}

.profile-content .text-muted {
  color: #cccccc !important;
}

.profile-content strong {
  color: #ffffff;
}

.profile-content .alert-danger {
  background-color: #dc3545;
  color: #ffffff;
  border-color: #bd2130;
}

.profile-content .alert-success {
  background-color: #28a745;
  color: #ffffff;
  border-color: #1e7e34;
}

/* Styles for GenerateIdeas component */
.generate-ideas-container {
  background-color: #2a2a2a;
  border-radius: 15px;
  padding: 30px;
  margin-top: 120px;
  margin-bottom: 120px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.generate-ideas-container h2 {
  color: #f2f2f2;
  font-size: 28px;
  text-align: center;
}

.generate-ideas-container .card {
  background-color: #333333;
  border: none;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.generate-ideas-container .card-body {
  padding: 25px;
}

.generate-ideas-container .form-control {
  background-color: #444444;
  border: 1px solid #555555;
  color: #f2f2f2;
}

.generate-ideas-container .form-control:focus {
  background-color: #4a4a4a;
  border-color: #8426f8;
  box-shadow: 0 0 0 0.2rem rgba(132, 38, 248, 0.25);
}

.generate-ideas-container .btn-primary {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  transition: all 0.3s ease;
}

.generate-ideas-container .btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(31, 138, 219, 0.3);
}

.generate-ideas-container .list-group-item {
  background-color: #444444;
  color: #f2f2f2;
  border: 1px solid #555555;
}

.generate-ideas-container .generated-content {
  background-color: #3a3a3a;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.generate-ideas-container .generated-content h5 {
  color: #1f8adb;
  border-bottom: 1px solid #555555;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.generate-ideas-container .generated-content p {
  color: #f2f2f2;
  line-height: 1.6;
}

.generate-ideas-container .btn-secondary,
.generate-ideas-container .btn-success {
  transition: all 0.3s ease;
}

.generate-ideas-container .btn-secondary:hover,
.generate-ideas-container .btn-success:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.generate-ideas-container .toast {
  background-color: #333333;
  color: #f2f2f2;
}

.generate-ideas-container .toast-header {
  background-color: #444444;
  color: #f2f2f2;
}

.generate-ideas-container .credits-remaining {
  color: #1f8adb;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.generate-ideas-container .alert-danger {
  background-color: #ff4444;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

.dashboard-container {
  margin: 40px auto;
}

.dashboard-container,
.generate-ideas-container {
  background-color: #2a2a2a;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.dashboard-container .card,
.generate-ideas-container .card {
  background-color: #333333;
  border: none;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  color: #f2f2f2;
}

.dashboard-container .card-body,
.generate-ideas-container .card-body {
  padding: 25px;
}

.dashboard-container .list-group-item,
.generate-ideas-container .list-group-item {
  background-color: #444444;
  color: #f2f2f2;
  border: 1px solid #555555;
}

.dashboard-container .progress-bar,
.generate-ideas-container .progress-bar {
  background-color: #1f8adb;
}

.dashboard-container .btn-primary,
.generate-ideas-container .btn-primary {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  transition: all 0.3s ease;
}

.dashboard-container .btn-primary:hover,
.generate-ideas-container .btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(31, 138, 219, 0.3);
}

.dashboard-container .btn-outline-primary,
.generate-ideas-container .btn-outline-primary {
  color: #1f8adb;
  border-color: #1f8adb;
}

.dashboard-container .btn-outline-primary:hover,
.generate-ideas-container .btn-outline-primary:hover {
  background-color: #1f8adb;
  color: #ffffff;
}

.generate-ideas-container .generated-content {
  background-color: #3a3a3a;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.generate-ideas-container .generated-content h5 {
  color: #1f8adb;
  border-bottom: 1px solid #555555;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.generate-ideas-container .generated-content p {
  color: #f2f2f2;
  line-height: 1.6;
}

.dashboard-container .text-muted,
.generate-ideas-container .text-muted {
  color: #cccccc !important;
}

.dashboard-container strong,
.generate-ideas-container strong {
  color: #ffffff;
}

.dashboard-container p,
.generate-ideas-container p {
  color: #f2f2f2;
}

.generate-ideas-container .form-control {
  background-color: #444444;
  border: 1px solid #555555;
  color: #f2f2f2;
}

.generate-ideas-container .form-control:focus {
  background-color: #4a4a4a;
  border-color: #1f8adb;
  box-shadow: 0 0 0 0.2rem rgba(31, 138, 219, 0.25);
}

.generate-ideas-container .btn-danger {
  background-color: #dc3545;
  border: none;
}

.generate-ideas-container .btn-danger:hover {
  background-color: #c82333;
}

.generate-ideas-container .toast {
  background-color: #333333;
  color: #f2f2f2;
}

.generate-ideas-container .toast-header {
  background-color: #444444;
  color: #f2f2f2;
}
.idea-vault-container {
  background-color: #1a1a1a;
  color: #f2f2f2;
  min-height: 100vh;
}

.idea-vault-title {
  color: #1f8adb;
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
}

.search-bar {
  background-color: #2a2a2a;
  border: none;
  color: #f2f2f2;
  border-radius: 20px;
  padding: 10px 20px;
}

.search-bar:focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 238, 254, 0.25);
}

.idea-preview {
  background-color: #2a2a2a;
  border: none;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.idea-preview:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(176, 89, 255, 0.3);
}

.idea-title {
  color: #1f8adb;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.idea-tags .badge {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  color: #1a1a1a;
  font-weight: 600;
  margin-right: 5px;
  white-space: normal;
}

.idea-meta {
  color: #f2f2f2;
  font-size: 0.9rem;
  margin-top: 10px;
}

.delete-btn {
  background-color: #ff4d4d;
  border: none;
  border-radius: 50%;
  color: #fff;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.delete-btn:hover {
  background-color: #ff3333;
}

.stats-card {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  border-radius: 10px;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.stats-card .card-body {
  padding: 20px;
}

.stats-card h4 {
  color: #1a1a1a;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.stats-card .list-group-item {
  background-color: transparent;
  border: none;
  color: #1a1a1a;
  padding: 5px 0;
}

.generate-card {
  background-color: #2a2a2a;
  border: none;
  border-radius: 10px;
  color: #f2f2f2;
}

.generate-card .card-body {
  padding: 20px;
}

.generate-card h4 {
  color: #1f8adb;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.generate-btn {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  color: #1a1a1a;
  font-weight: 600;
  padding: 10px 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.generate-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(42, 238, 254, 0.3);
}

.idea-modal .modal-content {
  background-color: #2a2a2a;
  color: #f2f2f2;
}

.idea-modal .modal-header {
  border-bottom: 1px solid #3a3a3a;
}

.idea-modal .modal-footer {
  border-top: 1px solid #3a3a3a;
}

.idea-modal .close {
  color: #f2f2f2;
}

.idea-content h2,
.idea-content h3 {
  color: #1f8adb;
}

.idea-content p {
  color: #f2f2f2;
}

.delete-modal .modal-content {
  background-color: #2a2a2a;
  color: #f2f2f2;
}

.delete-modal .modal-header {
  border-bottom: 1px solid #3a3a3a;
}

.delete-modal .modal-footer {
  border-top: 1px solid #3a3a3a;
}

.delete-modal .btn-secondary {
  background-color: #4a4a4a;
  border: none;
}

.delete-modal .btn-danger {
  background-color: #ff4d4d;
  border: none;
}

.accordion-body {
  padding: 1rem;
}

.accordion-body p {
  margin-bottom: 1rem;
}

.accordion-body ul,
.accordion-body ol {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.accordion-body li {
  margin-bottom: 0.5rem;
}

.accordion-body h3,
.accordion-body h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.accordion-button:not(.collapsed) {
  background-color: #e7f1ff;
  color: #0c63e4;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.tiktok-preview {
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  max-width: 350px;
  margin: 0 auto;
}

.video-placeholder {
  background-color: #fff;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-icon {
  font-size: 48px;
}

.content {
  padding: 15px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.caption {
  font-size: 14px;
  opacity: 0.8;
}

.hashtags {
  margin-top: 10px;
  font-size: 14px;
}

.hashtag {
  color: #5dadec;
  margin-right: 5px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.full-screen-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav-toggle {
  position: relative;
  z-index: 1100;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  z-index: 999;
}

.fullscreen-menu .nav-link {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.testimonial-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

.testimonial-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.creator-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.creator-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.creator-info {
  display: flex;
  flex-direction: column;
}

.creator-name {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

.review-date {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.metrics-badge {
  background: #e8f5e9;
  color: #2e7d32;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.testimonial-body {
  margin: 1.5rem 0;
}

.testimonial-text {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 1rem;
}

.value-generated {
  font-size: 1.2rem;
  color: #1976d2;
}

.tiktok-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #000;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 0;
}

.tiktok-link:hover {
  color: #1976d2;
}

.link-icon {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .reviews-grid {
    grid-template-columns: 1fr;
  }

  .testimonial-card {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .reviews-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  }
}

@media (max-width: 768px) {
  .tiktok-video-container {
    width: 100%;
    height: auto;
    aspect-ratio: 9/16;
  }
}
@media (min-width: 992px) {
  .fullscreen-menu {
    position: static;
    background-color: transparent;
    flex-direction: row;
  }

  .fullscreen-menu .nav-link {
    font-size: 1rem;
    margin: 0 1rem;
  }
}

/* Media Queries for smaller screens */
@media (max-width: 991px) {
  .generate-ideas-container .card-body {
    padding: 1rem;
  }

  .dashboard-container .btn-outline-primary,
  .generate-ideas-container .btn-primary,
  .generate-ideas-container .btn-outline-primary,
  .dashboard-container .btn-primary {
    font-size: 14px;
  }

  .generate-ideas-container h2 {
    font-size: 24px;
  }

  .edu-card {
    min-height: 300px;
    height: 100%; /* Ensures all cards are the same height */
    border-radius: 15px; /* Rounded corners */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transitions for hover effects */
  }
  .edu-card-title {
    margin-bottom: 30px; /* Adds a margin at the bottom of the title */
    text-align: center;
  }

  .edu-card-subtitle {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }
  .edu-card-text {
    font-size: 14px;
  }

  .planet-system {
    width: 130%;
    margin-left: -15%;
    margin-right: -15%;
  }

  .planet {
    right: -50%;
    width: 100%;
    position: absolute;
    height: auto;
    top: -600px;
    z-index: -1;
  }

  .moon {
    width: 5%;
    height: 5%;
    top: 50%;
    left: 85%;
  }

  @keyframes orbit {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translateX(100px)
        rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translateX(100px)
        rotate(-360deg);
    }
  }
}

@media (max-width: 767px) {
  .navbar-brand img {
    width: 100px;
  }

  .dashboard-container {
    margin: 20px auto;
  }

  .generate-ideas-container {
    margin-top: 30px;
    padding: 8px;
    margin-bottom: 80px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .footer-links {
    flex-direction: column;
  }

  .landing-header {
    font-size: 48px;
    font-weight: bold;
  }

  .landing-text {
    font-size: 16px;
  }

  .home-container {
    padding-top: 40px;
    padding-bottom: 40px;
    z-index: 2;
  }

  h5 {
    font-size: 18px;
  }

  .badge {
    font-size: 12px;
  }

  .main-content {
    padding-top: 1rem; /* Adjust for smaller screens if needed */
  }

  .planet-system {
    width: 130%;
    margin-left: -15%;
    margin-right: -15%;
    transform: translateX(15%);
  }

  .planet {
    right: -50%;
    width: 100%;
    position: absolute;
    height: auto;
    top: 120px;
    z-index: -1;
  }
}

@media (max-width: 576px) {
  h5 {
    font-size: 14px;
  }

  .planet-system {
    width: 140%;
    margin-left: -20%;
    margin-right: -20%;
    transform: translateX(20%);
  }

  .moon {
    width: 4%;
    height: 3%;
    left: 75%;
  }

  @keyframes orbit {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translateX(80px)
        rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translateX(80px)
        rotate(-360deg);
    }
  }

  .home-container {
    padding-bottom: 40px;
    z-index: 2;
  }
}

.reviews-container {
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.reviews-track {
  display: flex;
  gap: 2rem;
  padding: 1rem;
}

/* Desktop styles */
@media (min-width: 769px) {
  .reviews-track {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .reviews-container {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  .reviews-track {
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
    animation: scroll 20s linear infinite;
  }

  .testimonial-card {
    flex: 0 0 300px;
    margin-right: 1rem;
  }

  /* Hide scrollbar */
  .reviews-container {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .reviews-container::-webkit-scrollbar {
    display: none;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  /* Pause animation on hover */
  .reviews-track:hover {
    animation-play-state: paused;
  }
}

.tiktok-social-proof-section {
  padding: 100px 0;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.tiktok-social-proof-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  pointer-events: none;
}

.tiktok-section-header {
  margin-bottom: 80px;
  position: relative;
}

.tiktok-section-title {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
}

.tiktok-title-underline {
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 4px;
  background: linear-gradient(90deg, #1f8adb, #8426f8);
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(31, 138, 219, 0.5);
}

.tiktok-section-subtitle {
  font-size: 1.2rem;
  color: #a0a0a0;
  font-weight: 500;
  max-width: 600px;
  margin: 0 auto;
}

.tiktok-reviews-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.tiktok-reviews-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2rem 0;
}

.tiktok-testimonial-card {
  position: relative;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 2rem;
  height: 100%;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
}

.tiktok-testimonial-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, #1f8adb, #8426f8);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tiktok-testimonial-card:hover {
  transform: translateY(-10px);
  border-color: rgba(31, 138, 219, 0.2);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(31, 138, 219, 0.1);
}

.tiktok-testimonial-card:hover::before {
  opacity: 1;
}

.tiktok-card-content {
  position: relative;
  z-index: 1;
}

.tiktok-creator-profile {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.tiktok-creator-image-wrapper {
  position: relative;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.tiktok-creator-image {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  border: 2px solid rgba(31, 138, 219, 0.3);
}

.tiktok-metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1.25rem;
  background: rgba(31, 138, 219, 0.03);
  border-radius: 16px;
  margin: 1.5rem 0;
}

.tiktok-metric-item {
  text-align: center;
  padding: 0.75rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.tiktok-metric-value {
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0.5rem 0;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .tiktok-reviews-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .tiktok-reviews-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .tiktok-testimonial-card {
    padding: 1.5rem;
  }

  .tiktok-creator-profile {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .tiktok-metrics-grid {
    padding: 1rem;
    gap: 0.75rem;
  }
}

/* Enhanced animations */
.tiktok-testimonial-card {
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

.tiktok-testimonial-card:nth-child(1) {
  animation-delay: 0.1s;
}
.tiktok-testimonial-card:nth-child(2) {
  animation-delay: 0.2s;
}
.tiktok-testimonial-card:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced hover effects */
.tiktok-success-link {
  margin-top: 1.5rem;
  padding: 1rem;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border-radius: 12px;
  text-align: center;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.tiktok-success-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.tiktok-success-link:hover::after {
  left: 100%;
}

.tiktok-success-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(31, 138, 219, 0.3);
}

.tiktok-growth-showcase {
  background: linear-gradient(
    135deg,
    rgba(31, 138, 219, 0.1),
    rgba(132, 38, 248, 0.1)
  );
  padding: 1.5rem;
  border-radius: 16px;
  margin: 2rem 0;
  border: 1px solid rgba(31, 138, 219, 0.2);
  position: relative;
  overflow: hidden;
}

.tiktok-growth-showcase::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at top right,
    rgba(31, 138, 219, 0.1),
    transparent
  );
  pointer-events: none;
}

.tiktok-growth-metric {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1rem;
}

.tiktok-growth-icon {
  color: #00ff88;
  font-size: 1.4rem;
  filter: drop-shadow(0 0 4px rgba(0, 255, 136, 0.4));
}

.tiktok-growth-value {
  font-size: 1.6rem;
  font-weight: 800;
  color: #00ff88;
  letter-spacing: 0.5px;
  text-shadow: 0 0 10px rgba(0, 255, 136, 0.3);
}

.tiktok-growth-timeframe {
  font-size: 0.9rem;
  color: #a0a0a0;
  margin-left: 4px;
}

.tiktok-value-result {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tiktok-success-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  padding: 1.2rem;
  border-radius: 12px;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
}

.tiktok-success-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.6s;
}

.tiktok-success-link:hover::before {
  transform: translateX(100%);
}

.tiktok-link-icon {
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.tiktok-success-link:hover .tiktok-link-icon {
  transform: translateX(4px);
}

/* Desktop styles */
@media (min-width: 769px) {
  .tiktok-reviews-track {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .tiktok-section-title {
    font-size: 2.5rem;
  }

  .tiktok-reviews-container {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  .tiktok-reviews-track {
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
    animation: tiktok-scroll 30s linear infinite;
    padding: 1rem;
  }

  .tiktok-testimonial-card {
    margin-right: 2rem;
    flex: 0 0 340px;
    padding: 2rem;
  }

  @keyframes tiktok-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  .tiktok-reviews-track:hover {
    animation-play-state: paused;
  }
}

/* Animations */
@keyframes tiktok-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(31, 138, 219, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(31, 138, 219, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(31, 138, 219, 0);
  }
}

@keyframes tiktok-shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Enhanced scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #8426f8, #1f8adb);
}

/* Ultra-Modern Glass Cards */
.neon-card {
  background: rgba(15, 15, 20, 0.6);
  backdrop-filter: blur(20px) saturate(180%);
  border-radius: 24px;
  padding: 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(31, 138, 219, 0.15),
    inset 0 0 60px rgba(31, 138, 219, 0.02);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
}

.neon-card::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    125deg,
    rgba(255, 255, 255, 0.03) 0%,
    rgba(31, 138, 219, 0.05) 50%,
    rgba(255, 255, 255, 0.03) 100%
  );
  opacity: 0;
  transition: opacity 0.4s ease;
}

.neon-card:hover {
  transform: translateY(-8px) scale(1.02);
  border: 1px solid rgba(31, 138, 219, 0.2);
  box-shadow: 0 8px 40px rgba(31, 138, 219, 0.2),
    inset 0 0 80px rgba(31, 138, 219, 0.05);
}

.neon-card:hover::before {
  opacity: 1;
}

/* Premium Neon Icons */

.reason-icon {
  width: 45px;
  height: 45px;
  filter: drop-shadow(0 0 8px rgba(31, 138, 219, 0.6));
  transition: all 0.4s ease;
  z-index: 1;
}

/* Glowing Text */
.reason-title {
  font-size: 1.8rem;
  font-weight: 700;
  background: linear-gradient(135deg, #fff 0%, #1f8adb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 20px rgba(31, 138, 219, 0.3);
  margin-bottom: 1.5rem;
}

/* Premium Button */
.neon-button {
  background: rgba(31, 138, 219, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(31, 138, 219, 0.2);
  padding: 1rem 2.5rem;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
}

.neon-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(31, 138, 219, 0.2),
    rgba(132, 38, 248, 0.2)
  );
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.neon-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(31, 138, 219, 0.3), 0 0 40px rgba(31, 138, 219, 0.1);
  border-color: rgba(31, 138, 219, 0.4);
}

.neon-button:hover::before {
  transform: translateX(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .neon-card {
    padding: 2rem;
    margin-bottom: 2rem;
  }

  .neon-icon-bg {
    width: 75px;
    height: 75px;
  }

  .reason-icon {
    width: 35px;
    height: 35px;
  }

  .reason-title {
    font-size: 1.5rem;
  }
}

/* TestimonialShowcase Styles */
.testimonial-showcase {
}

.gradient-text {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.step-container {
  position: relative;
  z-index: 1;
}

.step-content-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.step-content-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(31, 138, 219, 0.1);
  border-color: rgba(31, 138, 219, 0.2);
}

.step-number .gradient-border {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.5rem;
}

.step-text {
  flex: 1;
}

.step-title {
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.step-description {
  color: #a0a0a0;
  font-size: 1rem;
  line-height: 1.6;
}

.testimonial-card-wrapper {
  margin-bottom: 2rem;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden;
}

.testimonial-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(31, 138, 219, 0.5),
    transparent
  );
}

.quote-icon {
  color: rgba(31, 138, 219, 0.3);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.testimonial-name {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.testimonial-content {
  color: #e0e0e0;
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.rating-container {
  display: flex;
  gap: 0.5rem;
}

.star-icon {
  font-size: 1.2rem;
  color: #555555;
  transition: all 0.3s ease;
}

.star-icon.active {
  color: #ffd700;
  filter: drop-shadow(0 0 3px rgba(255, 215, 0, 0.4));
}

@media (max-width: 768px) {
  .gradient-text {
    font-size: 2rem;
  }

  .step-content-wrapper {
    padding: 1rem;
  }

  .testimonial-card {
    padding: 1.5rem;
  }
}

/* Add these styles for FAQ section */
.accordion {
  background: transparent;
}

.accordion-item {
  background: rgba(255, 255, 255, 0.03) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  border-radius: 16px !important;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.3s ease;
}

.accordion-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(31, 138, 219, 0.1);
  border-color: rgba(31, 138, 219, 0.2) !important;
}

.accordion-button {
  background: transparent !important;
  color: #ffffff !important;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.5rem;
  position: relative;
  transition: all 0.3s ease;
}

.accordion-button:not(.collapsed) {
  background: rgba(31, 138, 219, 0.1) !important;
  color: #1f8adb !important;
  box-shadow: none;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%231f8adb' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E") !important;
  transition: all 0.3s ease;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%231f8adb' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E") !important;
}

.accordion-body {
  background: rgba(255, 255, 255, 0.01);
  color: #e0e0e0;
  font-size: 1rem;
  line-height: 1.7;
  padding: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

/* FAQ section container styles */
.faq-container {
  position: relative;
  padding: 4rem 0;
}

.faq-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
  text-align: center;
  background: linear-gradient(135deg, #ffffff 0%, #1f8adb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.faq-container h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #1f8adb, #8426f8);
  border-radius: 2px;
}

/* Add subtle glow effect on hover */
.accordion-item:hover .accordion-button {
  text-shadow: 0 0 10px rgba(31, 138, 219, 0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faq-container h2 {
    font-size: 2rem;
  }

  .accordion-button {
    font-size: 1rem;
    padding: 1.25rem;
  }

  .accordion-body {
    font-size: 0.95rem;
    padding: 1.25rem;
  }
}

/* Enhanced TikTok Conveyor styles */
.tiktok-social-proof-section {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(13, 12, 34, 0.8) 100%
  );
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  margin: 2rem 0;
  padding: 4rem 2rem;
}

.tiktok-testimonial-card {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px rgba(31, 138, 219, 0.1);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tiktok-testimonial-card:hover {
  transform: translateY(-10px) scale(1.02);
  border-color: rgba(31, 138, 219, 0.2);
  box-shadow: 0 15px 35px rgba(31, 138, 219, 0.2),
    0 0 50px rgba(31, 138, 219, 0.1);
}

.tiktok-creator-image {
  border: 2px solid transparent;
  background: linear-gradient(135deg, #1f8adb, #8426f8) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.tiktok-platform-icon-wrapper {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  box-shadow: 0 0 20px rgba(31, 138, 219, 0.3);
}

.tiktok-metrics-grid {
  background: rgba(31, 138, 219, 0.05);
  border: 1px solid rgba(31, 138, 219, 0.1);
  backdrop-filter: blur(10px);
  padding: 1.5rem;
  border-radius: 16px;
}

.tiktok-metric-icon {
  font-size: 1.5rem;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 0 8px rgba(31, 138, 219, 0.4));
}

.tiktok-growth-showcase {
  background: linear-gradient(
    135deg,
    rgba(31, 138, 219, 0.05),
    rgba(132, 38, 248, 0.05)
  );
  border: 1px solid rgba(31, 138, 219, 0.1);
  box-shadow: inset 0 0 30px rgba(31, 138, 219, 0.05);
}

.tiktok-success-link {
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  box-shadow: 0 4px 15px rgba(31, 138, 219, 0.3);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.tiktok-success-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.tiktok-success-link:hover::before {
  left: 100%;
}

/* Enhanced animations */
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.tiktok-section-title {
  animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px rgba(31, 138, 219, 0.2);
  }
  to {
    text-shadow: 0 0 20px rgba(31, 138, 219, 0.4);
  }
}

/* Responsive enhancements */
@media (max-width: 768px) {
  .tiktok-social-proof-section {
    padding: 2rem 1rem;
    margin: 1rem 0;
  }

  .tiktok-testimonial-card {
    padding: 1.5rem;
  }

  .tiktok-metrics-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

/* New Modern TikTok Conveyor Styles */
.tiktok-social-proof-section {
  background: linear-gradient(125deg, #000000, #0a0a0a, #000000);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 6rem 0;
}

.tiktok-social-proof-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(31, 138, 219, 0.15),
    transparent 70%
  );
  pointer-events: none;
}

.tiktok-section-title {
  font-size: 3.5rem;
  font-weight: 800;
  background: linear-gradient(to right, #fff, #1f8adb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 1;
}

.tiktok-section-subtitle {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 4rem;
}

.tiktok-reviews-container {
  position: relative;
  padding: 2rem 0;
}

.tiktok-reviews-track {
  display: flex;
  gap: 2rem;
  padding: 2rem;
}

.tiktok-testimonial-card {
  flex: 0 0 400px;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.tiktok-testimonial-card::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    125deg,
    rgba(31, 138, 219, 0.1) 0%,
    rgba(132, 38, 248, 0.1) 50%,
    rgba(31, 138, 219, 0.1) 100%
  );
  opacity: 0;
  transition: opacity 0.4s;
}

.tiktok-testimonial-card:hover::before {
  opacity: 1;
}

.tiktok-creator-profile {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.tiktok-creator-image-wrapper {
  position: relative;
  width: 80px;
  height: 80px;
}

.tiktok-creator-image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  border: 2px solid rgba(31, 138, 219, 0.3);
}

.tiktok-platform-icon-wrapper {
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  background: #1f8adb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(31, 138, 219, 0.3);
}

.tiktok-creator-info {
  flex: 1;
}

.tiktok-creator-name {
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0.3rem;
}

.tiktok-creator-niche {
  color: #666;
  font-size: 0.9rem;
}

.tiktok-metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.tiktok-metric-item {
  text-align: center;
}

.tiktok-metric-value {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1f8adb;
  display: block;
  margin: 0.5rem 0;
}

.tiktok-metric-label {
  color: #666;
  font-size: 0.9rem;
}

.tiktok-testimonial-text {
  color: #fff;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 2rem 0;
  font-style: italic;
  position: relative;
  padding-left: 1.5rem;
}

.tiktok-testimonial-text::before {
  content: '"';
  position: absolute;
  left: 0;
  top: -0.5rem;
  font-size: 2.5rem;
  color: #1f8adb;
  opacity: 0.5;
}

.tiktok-growth-showcase {
  background: rgba(31, 138, 219, 0.05);
  border-radius: 16px;
  padding: 1.5rem;
  margin: 2rem 0;
}

.tiktok-growth-metric {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.tiktok-growth-icon {
  color: #00ff88;
  font-size: 1.5rem;
}

.tiktok-growth-value {
  font-size: 2rem;
  font-weight: 800;
  color: #00ff88;
  text-shadow: 0 0 20px rgba(0, 255, 136, 0.3);
}

.tiktok-success-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background: linear-gradient(125deg, #1f8adb, #8426f8);
  border-radius: 12px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s;
}

.tiktok-success-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(31, 138, 219, 0.3);
}

.tiktok-link-icon {
  font-size: 0.9rem;
  transition: transform 0.3s;
}

.tiktok-success-link:hover .tiktok-link-icon {
  transform: translateX(4px);
}

@media (max-width: 768px) {
  .tiktok-section-title {
    font-size: 2.5rem;
  }

  .tiktok-testimonial-card {
    flex: 0 0 300px;
    padding: 1.5rem;
  }

  .tiktok-creator-image-wrapper {
    width: 60px;
    height: 60px;
  }

  .tiktok-metric-value {
    font-size: 1.5rem;
  }

  .tiktok-growth-value {
    font-size: 1.6rem;
  }
}

/* Update TikTok Conveyor styles */
.tiktok-testimonial-card {
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tiktok-testimonial-card:hover {
  transform: translateY(-10px) scale(1.02);
}

.tiktok-star-wrapper {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tiktok-title-underline {
  width: 0;
  height: 4px;
  background: linear-gradient(90deg, #1f8adb, #8426f8);
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(31, 138, 219, 0.5);
  animation: expandWidth 1s forwards 0.5s;
}

@keyframes expandWidth {
  to {
    width: 100%;
  }
}

.arrow-up {
  animation: bounceUp 0.3s ease;
}

@keyframes bounceUp {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

.tiktok-success-link {
  transition: all 0.3s ease;
}

.tiktok-success-link:hover {
  transform: translateY(-2px);
}

.tiktok-success-link:hover .tiktok-link-icon {
  transform: translateX(4px);
}

.tiktok-link-icon {
  transition: transform 0.3s ease;
}

/* Add clean morphism effects */
.tiktok-testimonial-card {
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.tiktok-testimonial-card:hover {
  box-shadow: 0 10px 20px rgba(31, 138, 219, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1);
  border-color: rgba(31, 138, 219, 0.2);
}

.tiktok-metrics-grid {
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.tiktok-growth-showcase {
  background: linear-gradient(
    135deg,
    rgba(31, 138, 219, 0.05),
    rgba(132, 38, 248, 0.05)
  );
  backdrop-filter: blur(8px);
  border: 1px solid rgba(31, 138, 219, 0.1);
}

/* Modern TikTok Showcase Styles */

.showcase-header {
  text-align: center;
  margin-bottom: 4rem;
}

.showcase-title {
  font-size: 3.5rem;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.showcase-accent {
  color: #1f8adb;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
}

.showcase-accent::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #1f8adb, transparent);
}

.showcase-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  padding: 2rem 0;
}

.showcase-card {
  background: #151515;
  border-radius: 20px;
  padding: 2rem;
  position: relative;
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.showcase-card:hover {
  transform: translateY(-8px);
  background: #1a1a1a;
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.creator-info {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.creator-avatar {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  object-fit: cover;
}

.creator-details h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.creator-details span {
  font-size: 0.85rem;
  color: #666;
}

.platform-badge {
  background: linear-gradient(135deg, #1f8adb33, #8426f833);
  padding: 0.5rem;
  border-radius: 10px;
  color: #1f8adb;
}

.metrics-showcase {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  background: #1d1d1d;
  padding: 1.5rem;
  border-radius: 15px;
}

.metric {
  text-align: center;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.metric-label {
  font-size: 0.85rem;
  color: #666;
  margin-top: 0.25rem;
}

.testimonial {
  font-size: 1rem;
  line-height: 1.6;
  color: #ccc;
  margin: 0;
  padding: 1rem;
  background: #1d1d1d;
  border-radius: 12px;
  border-left: 3px solid #1f8adb;
}

.growth-highlight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1d1d;
  padding: 1rem 1.5rem;
  border-radius: 12px;
}

.growth-stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00ff88;
  font-size: 1.2rem;
  font-weight: 600;
}

.growth-period {
  font-size: 0.85rem;
  color: #666;
}

.view-case {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  color: white;
  text-decoration: none;
  padding: 1rem;
  border-radius: 12px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.view-case:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(31, 138, 219, 0.3);
  color: white;
}

@media (max-width: 1200px) {
  .showcase-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .showcase-grid {
    grid-template-columns: 1fr;
  }

  .showcase-title {
    font-size: 2.5rem;
  }

  .showcase-accent {
    font-size: 1.5rem;
  }
}

/* Add these new styles for the redesigned TikTok showcase */
.tiktok-showcase {
  padding-bottom: 6rem 0;
  color: #fff;
}

.showcase-header {
  text-align: center;
  margin-bottom: 4rem;
}

.showcase-subtitle {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1f8adb;
  display: block;
  margin-bottom: 1rem;
}

.showcase-title {
  font-size: 2.5rem;
  font-weight: 800;
  background: linear-gradient(135deg, #fff 0%, #1f8adb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.showcase-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1rem 0;
}

.showcase-card {
  background: linear-gradient(145deg, #151515, #1a1a1a);
  border-radius: 20px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.03);
}

.showcase-card:hover {
  transform: translateY(-5px);
  border-color: rgba(31, 138, 219, 0.2);
  box-shadow: 0 10px 30px rgba(31, 138, 219, 0.1);
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.creator-avatar-wrapper {
  position: relative;
  width: 48px;
  height: 48px;
}

.creator-avatar {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.creator-platform {
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  background: #1f8adb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.creator-name {
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
  color: #fff;
}

.creator-niche {
  font-size: 0.75rem;
  color: #666;
  display: block;
  margin-top: 2px;
}

.growth-badge {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: rgba(0, 255, 136, 0.1);
  color: #00ff88;
  padding: 0.5rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
}

.metrics-bar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  background: rgba(255, 255, 255, 0.02);
  padding: 1rem;
  border-radius: 12px;
}

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.metric-label {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  color: #666;
  font-size: 0.75rem;
}

.metric-icon {
  color: #1f8adb;
  font-size: 0.875rem;
}

.metric-value {
  font-size: 1.125rem;
  font-weight: 700;
  color: #fff;
}

.review-quote {
  font-size: 0.875rem;
  line-height: 1.6;
  color: #ccc;
  margin: 0;
  padding: 0;
  font-style: italic;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.timeframe {
  font-size: 0.75rem;
  color: #666;
}

.timeframe span {
  color: #1f8adb;
  font-weight: 600;
}

.view-story {
  font-size: 0.75rem;
  color: #fff;
  text-decoration: none;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.view-story:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(31, 138, 219, 0.3);
  color: #fff;
}

@media (max-width: 1200px) {
  .showcase-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .showcase-grid {
    grid-template-columns: 1fr;
  }

  .showcase-title {
    font-size: 2rem;
  }

  .showcase-card {
    padding: 1.25rem;
  }
}

.footer-links {
  display: flex;
  gap: 10px;
}

.view-profile,
.view-story {
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
}

.view-profile {
  background-color: transparent;
  border: 1px solid #e4e4e4;
  color: #666;
}

.view-profile:hover {
  background-color: #f5f5f5;
  color: #333;
}

.script-header button.accordion-button {
  background: linear-gradient(45deg, #6a11cb 0%, #2575fc 100%) !important;
  color: white !important;
}

.script-header button.accordion-button::after {
  filter: brightness(0) invert(1);
}

.script-header button.accordion-button:not(.collapsed) {
  color: white !important;
  box-shadow: none;
}

.script-header button.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(106, 17, 203, 0.25);
}

/* Add these styles for a slanted progress bar */
.progress {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%) !important;
  position: relative;
  overflow: hidden;
}

.progress-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(255, 255, 255, 0.1) 10px,
    rgba(255, 255, 255, 0.1) 20px
  );
  animation: moveStripes 1s linear infinite;
}

@keyframes moveStripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 0;
  }
}

/* Update the progress bar height and text */
.progress {
  height: 25px !important;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

/* Smooth transitions for pricing cards */
.edu-card {
  transform: translateY(0);
  transition: all 0.3s ease;
}

.edu-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.custom-btn {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease !important;
}

.custom-btn:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.custom-btn:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Smooth fade for processing state */
.spinner-border {
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Auth Component Styles */
.auth-card {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border: none;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  overflow: hidden;
}

.auth-title {
  color: #333;
  font-weight: 700;
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
}

.auth-form .input-group {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.auth-form .input-group-text {
  background: white;
  border: 1px solid #e1e1e1;
  border-right: none;
  color: #666;
  padding: 0.75rem 1rem;
}

.auth-form .form-control {
  border: 1px solid #e1e1e1;
  border-left: none;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}

.auth-form .form-control:focus {
  box-shadow: none;
  border-color: #4299e1;
}

.auth-form .form-control:focus + .input-group-text {
  border-color: #4299e1;
}

.auth-form .btn-primary {
  background: linear-gradient(45deg, #4299e1, #667eea);
  border: none;
  padding: 0.75rem;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.auth-form .btn-primary:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(66, 153, 225, 0.2);
}

.auth-form .btn-outline-dark {
  border: 1px solid #e1e1e1;
  padding: 0.75rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.auth-form .btn-outline-dark:hover:not(:disabled) {
  background: #f7fafc;
  transform: translateY(-2px);
}

.auth-switch-btn {
  color: #4299e1;
  text-decoration: none;
  padding: 0;
  font-weight: 500;
}

.auth-switch-btn:hover {
  color: #2b6cb0;
  text-decoration: underline;
}

.divider-text {
  position: relative;
  display: inline-block;
  padding: 0 1rem;
  color: #666;
  font-size: 0.9rem;
}

.divider-text::before,
.divider-text::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100px;
  height: 1px;
  background: #e1e1e1;
}

.divider-text::before {
  right: 100%;
}

.divider-text::after {
  left: 100%;
}

/* Loading state styles */
.auth-form .btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

/* Alert styles */
.auth-form .alert {
  border-radius: 12px;
  font-size: 0.9rem;
  padding: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .auth-title {
    font-size: 1.8rem;
  }

  .auth-form .input-group-text,
  .auth-form .form-control {
    padding: 0.6rem 0.8rem;
  }
}

/* Auth Form Styles */
.auth-card {
  backdrop-filter: blur(10px);
}

.auth-form .form-control {
  transition: all 0.3s ease;
}

.auth-form .form-control:focus {
  background: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
}

.auth-form .input-group-text {
  transition: all 0.3s ease;
}

.auth-form .form-control:focus + .input-group-text,
.auth-form .input-group:focus-within .input-group-text {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
}

.auth-form button {
  transition: all 0.3s ease !important;
}

.auth-form button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(75, 0, 130, 0.4);
}

.auth-form .selected-plan {
  transition: all 0.3s ease;
}

.auth-form .selected-plan:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.auth-form .badge {
  box-shadow: 0 2px 8px rgba(75, 0, 130, 0.3);
}

/* Dark mode placeholder styles */
.auth-form .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}

/* Divider line styles */
.divider-text {
  position: relative;
  display: inline-block;
  padding: 0 10px;
}

.divider-text:before,
.divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.divider-text:before {
  right: 100%;
}

.divider-text:after {
  left: 100%;
}

/* Auth responsive padding */
@media (min-width: 992px) {
  .auth-container {
    padding-left: 80px !important;
    padding-right: 80px !important;
    padding-top: 180px !important;
  }

  .auth-card .card-body {
    padding: 2.5rem 3.5rem !important;
  }

  .membership-card .card-body {
    padding: 2.5rem 3.5rem !important;
  }
}

.auth-container {
  min-height: 100vh;
  padding-top: 80px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .auth-container {
    padding-top: 120px;
  }
}

/* Page Transition Styles */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

/* Enhanced Button Transitions */
.custom-btn {
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) !important;
  background: linear-gradient(135deg, #1f8adb, #8426f8);
  border: none;
  transform: translateY(0);
}

.custom-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.custom-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(31, 138, 219, 0.3);
}

.custom-btn:hover::before {
  left: 100%;
}

.custom-btn:active {
  transform: translateY(-1px);
  box-shadow: 0 3px 10px rgba(31, 138, 219, 0.2);
}
@keyframes fadeInFooter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.footer-div img {
  opacity: 0;
  animation: fadeInFooter 0.5s ease-in forwards;
}

@keyframes fadeInFooter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
